import React from 'react';
import { all } from 'q';


class AllResults extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    isWholeQuestionCorrect(result) {
        let isQuestionCorrect = true;
        if (result.question_type == 'multi_choice_block') {
            result.answers.map((answer) => {
                if (!answer.answered_correctly) {
                    isQuestionCorrect = false
                }
            })
        } else if (result.question_type == 'true_false_block') {
            if (!result.answered_correctly) {
                isQuestionCorrect = false
            }
        }

        if (isQuestionCorrect) {
            return true
        } else {
            return false
        }
    }

    isQuestionPartiallyCorrect(result) {
        let answers = []
        if (result.question_type == 'multi_choice_block') {
            result.answers.map((answer) => {
                if (!answer.answered_correctly) {
                    answers.push(false)
                } else {
                    answers.push(true)
                }
            })
        } else if (result.question_type == 'true_false_block') {
            if (!result.answered_correctly) {
                answers.push(false)
            } else {
                answers.push(true)
            }
        }

        return !answers.every(a => a === true) && answers.some(a => a === true)
    }

    renderSingleMultiChoiceResult(result, isWholeQuestionCorrect) {
        if(isWholeQuestionCorrect) {
            return (
                <>
                    <div className={result.correct_answer? "answer correct" : "answer incorrect"}>
                        <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input>
                        {result.correct_answer ? 
                            <label className="type">
                                {gettext('Rétt')}
                                {this.checkIcon()}
                            </label> : <label className="type">{gettext('Rangt')}</label>}
                        <div>
                            {result.answer}
                            { result.additional_info && <div class="small extra-info">{this.infoIcon()}{result.additional_info}</div>}
                        </div> 
                    </div>
                </>
            )
        } else {
            if (result.answered_correctly) {
                return (
                    <>
                        <div className={result.correct_answer? "answer correct" : "answer incorrect"}>
                            <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input>
                            {result.correct_answer ? 
                            <label className="type">
                                {gettext('Rétt')}
                                {this.checkIcon()}
                            </label> : <label className="type">{gettext('Rangt')}</label>}
                            <div className="correct-option">
                                {result.answer}
                                { result.additional_info && <div class="small extra-info">{this.infoIcon()}{result.additional_info}</div>}
                            </div>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className={result.correct_answer? "answer correct" : "answer incorrect"}>
                            <input type="radio" checked={result.correct_answer == false ? "checked" : ""} readOnly></input>
                            {result.correct_answer ? <label className="type">{gettext('Rétt')}</label> : 
                                <label className="type">
                                    {gettext('Rangt')}
                                    {this.checkIcon()}
                                </label>}
                            <div className="incorrect-option">
                                {result.answer}
                                { result.additional_info && <div class="small extra-info">{this.infoIcon()}{result.additional_info}</div>}
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    renderSingleTrueFalseResult(result) {
        if (result.answered_correctly) {
            return (
                <>
                    <div className="answer correct">
                        <div className="answer-container true">
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input>
                                <label>{gettext('Satt')}</label>
                            </div>
                        </div>
                        <div className="answer-container false">
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == false ? "checked" : ""} readOnly></input>
                                <label>{gettext('Ósatt')}</label>
                            </div>
                        </div>
                        { result.additional_info && <div class="small extra-info">{this.infoIcon()}{result.additional_info}</div>}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="answer incorrect"> 
                        <div className={`answer-container true ${result.correct_answer == true ? "answer-true" : "answer-false"}`}>
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == false ? "checked" : ""} readOnly></input><label>{gettext('Satt')}</label>
                            </div>
                        </div> 
                        <div className={`answer-container false ${result.correct_answer == false ? "answer-true" : "answer-false"}`}>
                            <div className="radio-container">
                                <input type="radio" checked={result.correct_answer == true ? "checked" : ""} readOnly></input><label>{gettext('Ósatt')}</label>
                            </div>
                        </div>
                        { result.additional_info && <div class="small extra-info">{this.infoIcon()}{result.additional_info}</div>}
                    </div>
                </>
            )
        }
    }

    getResult(result) {
        let isWholeQuestionCorrect = this.isWholeQuestionCorrect(result) ? true : false
        if (result.question_type == 'multi_choice_block') {
            let allResults =
                result.answers.map((answer) => {
                    return this.renderSingleMultiChoiceResult(answer, isWholeQuestionCorrect)
                })
            return allResults
        } else if (result.question_type == 'true_false_block') {
            return this.renderSingleTrueFalseResult(result)
        }
    }

    checkIcon() {
        return(
            <svg width="25px" height="24px" viewBox="0 0 25 24">
                <g id="Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Verkefni-yfirferð" transform="translate(-339.000000, -931.000000)" fill="#34B843" fill-rule="nonzero">
                        <polygon id="Path-Copy-2" points="347.57711 955 339 946.419531 342.206898 943.346994 347.132286 948.275173 360.415659 931 364 933.641877"></polygon>
                    </g>
                </g>
            </svg>
        )
    }
    
    infoIcon() {
        return(
            <svg version="1.1" id="info" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 16 16" style={{'enable-background':'new 0 0 16 16'}}>
            <g id="group" transform="translate(8.000000, 10.000000)">
                <circle id="circle" style={{'fill-rule':'evenodd', 'clip-rule':'evenodd', 'fill':'#1C5994'}} cx="0" cy="-2" r="8"/>
                <path id="i" style={{'fill':'#FFFFFF'}} d="M1.1-5.5c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C1.7-7.4,1.4-7.5,1.1-7.5
                    c-0.3,0-0.5,0.1-0.7,0.3C0.2-7,0.1-6.8,0.1-6.5c0,0.3,0.1,0.5,0.3,0.7S0.9-5.5,1.1-5.5z M-0.7,2.2c0.8,0,1.5-0.6,2.2-1.8l0,0
                    L1.2,0.3C0.9,0.7,0.7,1,0.5,1.2C0.4,1.2,0.3,1.3,0.2,1.3c0,0-0.1,0-0.1-0.1S0.1,1.1,0.1,1.1c0-0.1,0-0.3,0.1-0.5l0,0l1.4-4.9H1.2
                    L-1.2-4l-0.1,0.3c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.4,0.1s0.1,0.2,0.1,0.3c0,0.2,0,0.4-0.1,0.7l0,0l-1,3.3c-0.1,0.3-0.1,0.5-0.1,0.6
                    c0,0.3,0.1,0.5,0.3,0.6S-1,2.2-0.7,2.2z"/>
            </g>
            </svg>
        )
    }

    triangleIcon() {
        return(
            <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 23">
            <defs/>
            <path fill="#ffd321" d="M28 23L14 0 0 23h28zM14 7l7 12H7l7-12z"/>
            </svg>
        )
    }

    closeIcon() {
        return(
            <svg width="22px" height="22px" viewBox="0 0 22 22">
                <g id="Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Verkefni-yfirferð" transform="translate(-341.000000, -1163.000000)" fill="#DD2121">
                        <path d="M355.142857,1174 L363,1181.85714 L359.857143,1185 L352,1177.14286 L344.142857,1185 L341,1181.85714 L348.857143,1174 L341,1166.14286 L344.142857,1163 L352,1170.85714 L359.857143,1163 L363,1166.14286 L355.142857,1174 Z" id="Combined-Shape-Copy"></path>
                    </g>
                </g>
            </svg>
        )
    }

    getAnswersContainerClasses(result) {
        let classes = "answers-container"
        if(result.question_type == 'true_false_block') {
            classes = classes + " radio"
        } else {
            classes = classes + " checkbox";
        }

        if(this.isWholeQuestionCorrect(result)) {
            classes = classes + " correct"
        } else {
            classes = classes + " incorrect"
        }
        return classes;
    }

    renderResults(results) {
        let allResults = results.map((result, i) => {
            return (
                <div className="results-container">
                    <h1 className="title">{gettext('Spurning')} {i + 1}</h1>
                    <span className="true-false">
                        {this.isQuestionPartiallyCorrect(result) ?
                            <>
                                {this.triangleIcon()}
                            </>
                            :
                            <>
                                {this.isWholeQuestionCorrect(result) ? this.checkIcon() : this.closeIcon() }
                            </>
                       }
                    </span>
                    {result.image !== "" ? <div className="img-container"><img src={result.image}></img></div> : null}
                    <div className="question">{result.question}</div>
                    <div className={this.getAnswersContainerClasses(result)}>{this.getResult(result)}</div>
                </div>
            )
        })
        return allResults
    }

    closeAllResults() {
        this.props.toggleAllResultsActive();
    }

    render() {
        let results = this.props.results;
        return (
            <div className="all-results">
                {this.renderResults(results)}
                <div className="results-overview-navigation">
                    <button className="button prev" onClick={this.closeAllResults.bind(this)}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                        </svg>
                    </button>
                    <a className="button re-do" href={window.location.href}>{gettext('Endurtaka verkefnið')}</a>
                </div>
            </div>
        )
    }
}

AllResults.defaultProps = {
}


export default AllResults;