import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const pagesTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'PAGES');

export const requestPages = () => {
    return {
        type: pagesTypes.REQUEST,
    };
};

export const receivePages = (response, query={}) => {
    return {
        type: pagesTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receivePagesError = () => {
    return {
        type: pagesTypes.ERROR,
    };
};

export const fetchPages = (type) => {
    return dispatch => {
        dispatch(requestPages());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v2/pages/?type=` + type, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receivePages(response));
                } catch (e) {
                    dispatch(receivePagesError(response))
                }


            })
            .catch(handleNetworkError);
    }
}