import React from 'react'
import ReactDOM from 'react-dom'
import ExamPage from './exampage'
import { store } from '../../store' 

$.fn.exampage = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(ExamPage, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-exam-page]').exampage();
});