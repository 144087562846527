import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { answers } from './api/answers/reducers'
import { exams } from './api/exams/reducers'
import { pages } from './api/pages/reducers'

import { composeWithDevTools } from 'redux-devtools-extension';


const loggerMiddleware = createLogger()

const reducers = combineReducers({
    answers,
    exams,
    pages,
})


let middleware = null
if(process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
        
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
}

export const store = createStore(reducers, middleware)
